import React, { useEffect, useState } from 'react';
import Header from '../componets/Header';
import Footer from '../componets/Footer';
// import Cards from '../componets/Cards';
import Collaborate from '../componets/Collaborate';
// import ProjectDataFetcher from '../data/Projectsdata';
import { useRef } from 'react';
import gsap from 'gsap';
import { useGSAP } from '@gsap/react';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import config from '../config';
import blogImage from '../assets/blog_image.png';
import { Link } from "react-router-dom";
import { blogPosts } from '../data/blogPosts';


gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(useGSAP);

let isLoading = true;

export const getSortedBlogs = (blogs) => {
    return [...blogs].sort((a, b) => new Date(b.date) - new Date(a.date));
};

export const getBlogById = async (id) => {
    try {
        const response = await fetch(`${config.apiUrl}api/blogPosts/${id}`);
        const blog = await response.json();
        return blog;
    } catch (error) {
        console.error('Error fetching blog by id:', error);
        return null;
    }
};




function Cards({ blogs, loading }) {  // Accept blogs and loading as props
    if (loading) {
        return <div>Loading...</div>;
    }

    if (!blogs || blogs.length === 0) {
        return <div>No blogs available</div>;
    }



    return (
        <div className="grid justify-center gap-8 px-8 py-10 grid-cols-1 lg:grid-cols-3">
            {[...blogs].reverse().map(blog => {
             
                return (
                    <Link to={`/blog/${blog._id}`} key={blog._id}>
                        <div className="w-full overflow-hidden flex flex-col gap-4 pt-4">
                            <div className="rounded-[24px] relative overflow-hidden">
                                <img src={`${config.apiUrl}uploads/${blog.image.split('/').pop()}`} alt={blog.title} className="w-full h-80 object-cover rounded-[24px]" />
                                <h1 className="absolute top-0 right-0 bg-white py-2 px-4 m-4 text-sm rounded-[100px]">{blog.category}</h1>
                            </div>
                            <div className="flex flex-col gap-4">
                                <h1 className="font-bold text-xl mb-2">{blog.title}</h1>
                                <div className="font-[Inter] flex gap-4 items-center text-[#9A9A9A] text-[14px] font-medium pt-[16px] border-t-[0.5px] border-[#F3F3F3]">
                                    <p>{new Date(blog.date).toLocaleDateString()}</p>
                                    <div className='bg-black h-2 w-2 rounded-xl'></div>
                                    <p>Blog</p>
                                </div>
                            </div>
                        </div>
                    </Link>
                )
            })}
        </div>
    );
}

const MostRecentBlog = ({ blogs, loading }) => {

    const [isHoveringContact, setIsHoveringContact] = useState(false);

    if (loading || !blogs || blogs.length === 0) {
        return null;
    }

    const mostRecent = [...blogs].reverse()[0];




    const handleMouseEnterContact = () => {
        setIsHoveringContact(true);
        const dotElement = document.getElementById('dot');
        if (dotElement) {
            dotElement.classList.add('remove');
        }
    };

    const handleMouseLeaveContact = () => {
        setIsHoveringContact(false);
        const dotElement = document.getElementById('dot');
        if (dotElement) {
            dotElement.classList.remove('remove');
        }
    };

    const content = typeof mostRecent.content === 'object'
        ? mostRecent.content.mainContent
        : mostRecent.content;

    return (
        <div className="flex flex-col lg:flex-row px-8 py-10 gap-[64px]">
            <img 
                src={`${config.apiUrl}uploads/${mostRecent.image.split('/').pop()}`} 
                alt={mostRecent.title} 
                className="lg:w-1/2 w-full h-[300px] sm:h-[400px] lg:h-[500px] object-cover rounded-[30px]" 
            />
            <div className='flex flex-col justify-between py-4 lg:w-1/2 w-full'>
                <div className="flex flex-col gap-4">
                    <div className="font-[Inter] flex gap-4 items-center text-[#9A9A9A] text-[14px] font-medium pt-[16px] self-stretch">
                        <p>{new Date(mostRecent.date).toLocaleDateString()}</p>
                        <div className='bg-black h-2 w-2 rounded-xl'></div>
                        <p>{mostRecent.category}</p>
                    </div>
                    <h2 className='font-[Inter] text-[#141414] text-[40px] font-semibold'>{mostRecent.title}</h2>
                    <p className='text-[#4A4A4A] text-[16px] font-medium font-[Inter]'>{mostRecent.description}</p>
                </div>
                <Link className='hide' to={`/blog/${mostRecent._id}`}>
                    <button className="flex mt-8 lg:px-8 px-8 py-3 justify-center items-start gap-[8px] rounded-full bg-black text-[16px] font-semibold leading-[18px] text-white hover:shadow-xl hover:bg-white hover:border-[1px] hover:border-gray-300 hover:text-black border-[1px] border-black">
                        Continue Reading
                    </button>
                </Link>
            </div>
        </div>
    );
};


export default function Blogs() {

    const [blogs, setBlogs] = useState([]);
    const [loading, setLoading] = useState(true);
    const [isVisible] = useState(true);
    const [isOpen] = useState(false);
    const [selectedBlog, setSelectedBlog] = useState(null);


    useEffect(() => {
        fetchBlogs();
    }, []);

    const fetchBlogs = async () => {
        setLoading(true); // Explicitly set loading state
        console.log('Fetching blogs...');
        try {
            const response = await fetch(`${config.apiUrl}api/blogPosts/`, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                mode: 'cors'
            });
            console.log('Response received:', response);

            const contentType = response.headers.get("content-type");
            let data;

            if (contentType && contentType.indexOf("application/json") !== -1) {
                data = await response.json();
            } else {
                const textData = await response.text();
                data = JSON.parse(textData);
            }

            console.log('Parsed data:', data);
            setBlogs(data);
            setLoading(false);
        } catch (error) {
            console.log('Error fetching blogs:', error);
            setLoading(false);
        }
    };



    const textheadRef = useRef(null);
    const cus1Ref = useRef(null);
    const cus2Ref = useRef(null);
    const cus3Ref = useRef(null);
    const cus4Ref = useRef(null);
    const cus5Ref = useRef(null);
    const cus6Ref = useRef(null);
    const cus7Ref = useRef(null);
    const cus8Ref = useRef(null);
    const cus9Ref = useRef(null);
    const cus10Ref = useRef(null);
    const mainfaqRef = useRef(null);
    const faq1Ref = useRef(null);
    const faq2Ref = useRef(null);
    const faq3Ref = useRef(null);
    const faq4Ref = useRef(null);
    const faq5Ref = useRef(null);
    const faq6Ref = useRef(null);
    const cardRef = useRef(null);
    const newimg1Ref = useRef(null);
    const newimg2Ref = useRef(null);
    const newimg3Ref = useRef(null);
    const newimg4Ref = useRef(null);
    const newimg5Ref = useRef(null);
    const newimg6Ref = useRef(null);
    const mainimgRef = useRef(null);
    const newimg12Ref = useRef(null);
    const newimg22Ref = useRef(null);
    const newimg32Ref = useRef(null);
    const newimg42Ref = useRef(null);
    const newimg52Ref = useRef(null);
    const newimg62Ref = useRef(null);

    useGSAP(() => {
        const texthead = textheadRef.current;
        const cus1 = cus1Ref.current;
        const cus2 = cus2Ref.current;
        const cus3 = cus3Ref.current;
        const cus4 = cus4Ref.current;
        const cus5 = cus5Ref.current;
        const cus6 = cus6Ref.current;
        const cus7 = cus7Ref.current;
        const cus8 = cus8Ref.current;
        const cus9 = cus9Ref.current;
        const cus10 = cus10Ref.current;
        const mainfaq = mainfaqRef.current;
        const faq1 = faq1Ref.current;
        const faq2 = faq2Ref.current;
        const faq3 = faq3Ref.current;
        const faq4 = faq4Ref.current;
        const faq5 = faq5Ref.current;
        const faq6 = faq6Ref.current;
        const img1 = newimg1Ref.current;
        const img2 = newimg2Ref.current;
        const img3 = newimg3Ref.current;
        const img4 = newimg4Ref.current;
        const img5 = newimg5Ref.current;
        const img6 = newimg6Ref.current;
        const mainimg = mainimgRef.current;
        const img12 = newimg12Ref.current;
        const img22 = newimg22Ref.current;
        const img32 = newimg32Ref.current;
        const img42 = newimg42Ref.current;
        const img52 = newimg52Ref.current;
        const img62 = newimg62Ref.current;

        gsap.from(texthead, {
            x: -400,
            opacity: 0,
            duration: 1.2,
            scrollTrigger: {
                trigger: texthead,
                markers: false,
                start: 'top 100%'
            }
        });

        gsap.from(cus1, {
            yPercent: 350,
            duration: 2.3,
            ease: 'power4',
            smooth: 1
        });
        gsap.from(cus2, {
            yPercent: 200,
            duration: 1.6,
            smooth: 1
        });
        gsap.from(cus3, {
            yPercent: 200,
            duration: 1.9,
            smooth: 1
        });
        gsap.from(cus4, {
            yPercent: 200,
            duration: 2,
            smooth: 1
        });
        gsap.from(cus5, {
            yPercent: 200,
            duration: 2.2,
            smooth: 1
        });
        gsap.from(cus6, {
            yPercent: 200,
            duration: 2.4,
            smooth: 1
        });
        gsap.from(cus7, {
            yPercent: 200,
            opacity: 0,
            duration: 1.2,
            smooth: 1
        });
        gsap.from(cus8, {
            y: 400,
            duration: 1.8,
            smooth: 1,
            stagger: 2,
            scrollTrigger: {
                trigger: cus8Ref.current,
                markers: false,
                start: 'top 150%'
            }
        });
        gsap.from(cus9, {
            y: 200,
            opacity: 0,
            duration: 1.3,
            smooth: 1
            // scrollTrigger: {
            //   trigger: cus9,
            //   markers: false,
            //   start: "top 150%"
            // }
        });

        gsap.from(cus10, {
            y: 150,
            opacity: 0,
            duration: 1.8,
            smooth: 1,
            scrollTrigger: {
                trigger: cus10,

                markers: false,
                start: 'bottom 120%'
            }
        });

        gsap.from(mainfaq, {
            scrollTrigger: {
                trigger: mainfaq,
                markers: false,
                start: 'top 10%'
            }
        });
        gsap.from(faq1, {
            scale: 0,
            duration: 1,
            smooth: 1,
            scrollTrigger: {
                trigger: mainfaq,
                markers: false,
                start: 'top 110%'
            }
        });
        gsap.from(faq2, {
            scale: 0,
            duration: 1.3,
            smooth: 1,
            scrollTrigger: {
                trigger: mainfaq,
                markers: false,
                start: 'top 100%'
            }
        });
        gsap.from(faq3, {
            scale: 0,
            duration: 1.6,
            smooth: 1,
            scrollTrigger: {
                trigger: mainfaq,
                markers: false,
                start: 'top 90%'
            }
        });

        gsap.from(faq4, {
            scale: 0,
            duration: 1.8,
            smooth: 1,
            scrollTrigger: {
                trigger: mainfaq,
                markers: false,
                start: 'top 80%'
            }
        });
        gsap.from(faq5, {
            scale: 0,
            duration: 2,
            smooth: 1,
            scrollTrigger: {
                trigger: mainfaq,
                markers: false,
                start: 'top 75%'
            }
        });
        gsap.from(faq6, {
            scale: 0,
            duration: 2,
            smooth: 1,
            scrollTrigger: {
                trigger: mainfaq,
                markers: false,
                start: 'top 70%'
            }
        });
        gsap.from(img1, {
            y: 200,
            opacity: 0,
            duration: 2,
            smooth: 1,
            scrollTrigger: {
                trigger: mainimg,
                markers: false,
                start: 'top 70%'
            }
        });
        gsap.from(img12, {
            scale: 4,
            duration: 2,
            smooth: 1,
            scrollTrigger: {
                trigger: mainimg,
                markers: false,
                start: 'top 70%'
            }
        });

        gsap.from(img2, {
            y: 200,
            opacity: 0,
            duration: 2,
            smooth: 1,
            scrollTrigger: {
                trigger: mainimg,
                markers: false,
                start: 'top 70%'
            }
        });
        gsap.from(img22, {
            scale: 4,
            duration: 2,
            smooth: 1,
            scrollTrigger: {
                trigger: mainimg,
                markers: false,
                start: 'top 70%'
            }
        });
        gsap.from(img3, {
            y: 200,
            opacity: 0,
            duration: 2,
            smooth: 1,
            scrollTrigger: {
                trigger: mainimg,
                markers: false,
                start: 'top 70%'
            }
        });
        gsap.from(img32, {
            scale: 4,
            duration: 2,
            smooth: 1,
            scrollTrigger: {
                trigger: mainimg,
                markers: false,
                start: 'top 70%'
            }
        });
        gsap.from(img4, {
            y: 200,
            opacity: 0,
            duration: 2,
            smooth: 1,
            scrollTrigger: {
                trigger: mainimg,
                markers: false,
                start: 'top 50%'
            }
        });
        gsap.from(img42, {
            scale: 4,
            duration: 2,
            smooth: 1,
            scrollTrigger: {
                trigger: mainimg,
                markers: false,
                start: 'top 50%'
            }
        });
        gsap.from(img5, {
            y: 200,
            opacity: 0,
            duration: 2,
            smooth: 1,
            scrollTrigger: {
                trigger: mainimg,
                markers: false,
                start: 'top 50%'
            }
        });
        gsap.from(img52, {
            scale: 4,
            duration: 2,
            smooth: 1,
            scrollTrigger: {
                trigger: mainimg,
                markers: false,
                start: 'top 50%'
            }
        });
        gsap.from(img6, {
            y: 200,
            opacity: 0,
            duration: 2,
            smooth: 1,
            scrollTrigger: {
                trigger: mainimg,
                markers: false,
                start: 'top 50%'
            }
        });
        gsap.from(img62, {
            scale: 4,
            duration: 2,
            smooth: 1,
            scrollTrigger: {
                trigger: mainimg,
                markers: false,
                start: 'top 50%'
            }
        });
    });





    return (
        <div className={`page ${isVisible ? 'slide-in' : ''}`}>
            <Header />
            <div className="blur-section">
                <div className="px-8 py-10 ">
                    <div className="">
                        <h1 className="text-[#141414] text-[36px] sm:text-[48px] md:text-[56px] lg:text-[74px] font-bold flex gap-2 overflow-hidden flex-wrap w-100 ">
                            <span ref={cus2Ref}>Blogs</span>
                        </h1>
                    </div>


                </div>

                <MostRecentBlog blogs={blogs} loading={loading}/>

                <Cards blogs={blogs} loading={loading} />
                <Collaborate isOpen={isOpen} />
                <Footer />
            </div>
        </div>
    )
}

