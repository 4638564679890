import React, { useState } from 'react';
import config from '../config';
import frame from '../assets/logo.svg';
import emailjs from 'emailjs-com';

const CollabPopup = ({ jobTitle, applicationId, isOpen, onclose }) => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [contact, setContact] = useState('');
  const [resume, setResume] = useState(null);
  const [message, setMessage] = useState('');
  const [submitted, setSubmitted] = useState(false);

  const handleSubmit = (event) => {
    event.preventDefault();

    if (name.trim() === '' || email.trim() === '' || contact.trim() === '') {
      alert('Please fill out all fields');
      return;
    }

    // EmailJS parameters
    const templateParams = {
      from_name: name,
      from_email: email,
      phone: contact,
      message: message,
    };

    // Replace with your EmailJS service ID, template ID, and user ID
    const serviceId = 'service_d62dw16';
    const templateId = 'template_okfq7ok';
    const userId = 'teQKdcKGDcXTxWc0P';

    // Send email using EmailJS
    emailjs
      .send(serviceId, templateId, templateParams, userId)
      .then((response) => {
        console.log('Email sent:', response.status, response.text);
        setSubmitted(true); // Update state after successful submission
        alert('Application submitted successfully!');
        // onClose();
      })
      .catch((error) => {
        console.error('Email error:', error);
        alert('Error submitting application. Please try again.');
      });
  };

  return (
    <div onClick={onclose} className={`fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50 p-4 ${isOpen ? 'block' : 'hidden'}`}>
      <div onClick={(e) => e.stopPropagation()} className="rounded-lg p-4 w-full max-w-4xl min-h-screen flex items-center justify-center">
        <form onSubmit={handleSubmit} className="flex items-center justify-center w-full">
          <div className="w-full lg:w-[488px] items-center gap-8 bg-white rounded-3xl overflow-hidden flex flex-col relative">
            <div className="flex items-center gap-2 px-5 py-3 relative self-stretch w-full bg-[#e7e7e7] border-b border-[#d3d3d3]">
              <img className="relative w-[157px] h-[67px] mix-blend-darken object-cover" alt="Frame" src={frame} />
            </div>
            <div className="items-start gap-6 px-5 py-0 self-stretch w-full flex flex-col relative">
              <input className="self-stretch rounded-[50px] w-full border-black px-6 py-4 text-gray-400 border-solid border" placeholder="Name" value={name} onChange={(e) => setName(e.target.value)} required />
              <input className="self-stretch rounded-[50px] w-full border-gray-600 px-6 py-4 text-gray-400 border-solid border" placeholder="Email" type="email" value={email} onChange={(e) => setEmail(e.target.value)} required />
              <input className="self-stretch rounded-[50px] w-full border-gray-600 px-6 py-4 text-gray-400 border-solid border" placeholder="Phone No." value={contact} onChange={(e) => setContact(e.target.value)} required />
              <textarea className="self-stretch h-[127px] rounded-xl w-full border-gray-600 px-6 py-4 items-start grow text-gray-400 border-solid border" placeholder="Tell us about yourself" value={message} onChange={(e) => setMessage(e.target.value)} required />
            </div>
            <div className="flex flex-col items-end justify-center gap-2 px-5 py-3 relative self-stretch w-full bg-white border-t border-[#d3d3d3]">
              <div className="w-full sm:w-[119px] items-start gap-2.5 flex flex-col relative">
                <button className="self-stretch rounded-[100px] justify-center px-8 py-4 flex w-full text-white bg-black" type="submit">
                  Submit
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CollabPopup;