import React from 'react'
import Header from '../componets/Header';
import Footer from '../componets/Footer';

export default function ShippingPolicies() {

    const data = [
        {
            title: "Nature of Products Provided ",
            description: "Pixel Bharat delivers digital assets and software solutions, including but not limited to: ",
            points: [
                "Software tools and applications ",
                "Website plugins and extensions ",
                "Design templates and graphics ",
                "Stock photography and video content ",
                "Digital guides, eBooks, and educational materials ",
                "Other downloadable files (e.g., PDFs, audio files, presentations) ",
            ],
            footer: "Since these are digital products, no physical item will be shipped to you, and all transactions and product deliveries are conducted via the internet. Access to purchased content is provided through secure digital means, as described below. "
        },
        {
            title: "Digital Product Delivery ",
            description: "As a customer of Pixel Bharat, you will receive your purchased digital products or software electronically. After your purchase has been successfully processed, one or more of the following methods will be used to deliver your product: ",

            points: [
                "Email Delivery: After you complete your purchase, you will receive a confirmation email containing a download link or access key for the digital product you have purchased. This link will allow you to immediately download the product or access the service. ",
                "Account Download: In some cases, your purchase will be available in your Pixel Bharat account, where you can log in and download the digital asset at your convenience. The account dashboard will include a section for your orders or purchases, where your downloadable content will be stored. ",
                "Direct Download: Upon completing your purchase, you may be immediately redirected to a webpage where the download begins automatically or where you are provided with a link to manually initiate the download. ",
                "License Keys: If you are purchasing software or a service that requires activation, a unique license key will be provided to you via email or account dashboard. This key will allow you to activate your software product upon installation. "
            ]
        },
        {
            title: "Delivery Timeframes ",
            description: "Since Pixel Bharat provides digital products, delivery occurs immediately after payment is confirmed. You should be able to download or access your purchased item within a few minutes after the transaction is completed. ",

            points: [
                "Instant Access: For most products, you will receive immediate access to your files upon completing the checkout process. The delivery email or download link will be sent instantly. ",
                "Delayed Access: In rare cases, there may be a slight delay (up to 24 hours) in providing access due to factors such as verification, processing errors, or technical issues. If this occurs, our support team will notify you and resolve the issue as quickly as possible. ",
            ],
            footer: "If you do not receive your digital product within 24 hours of purchase, please contact our customer service team at contact@pixelbharat.com, and we will promptly assist you in accessing your files. "
        },
        {
            title: "Download Limits and Expiration",
            description: "To ensure smooth access to our digital products and prevent unauthorized distribution, Pixel Bharat enforces certain limitations on downloads. These include: ",
            points: [
                "Number of Downloads: Customers are typically allowed multiple downloads of each purchased product to ensure they can retrieve their files across multiple devices or in case of accidental loss of data. However, Pixel Bharat reserves the right to limit the number of downloads per product to prevent abuse or unauthorized sharing. ",
                "Download Expiration: Depending on the nature of the product, the download link provided may have an expiration date. Typically, links remain active for a minimum of 30 days from the purchase date. We encourage you to download your product promptly after purchase to avoid any issues with expired links. ",
                "Re-accessing Expired Downloads: If your download link expires before you have successfully retrieved your digital product, please contact our support team, and we will assist you with accessing your files. "
            ]
        },
        {
            title: "Software and License Activation",
            description: "Some of Pixel Bharat’s digital products, particularly software and plugins, may require activation using a license key or activation code. This code will be provided to you upon purchase and will be sent to your email address or displayed in your account dashboard. ",
            points: [
                "License Key Delivery: Your software license key will typically be delivered via email along with instructions on how to activate the software.",
                "Activation Process: After downloading and installing the software, you will be prompted to enter the license key to activate it. This process ensures that you are the rightful owner of the software and prevents unauthorized use. ",
                "License Restrictions: Each license key is typically restricted to a specific number of activations, which may vary depending on the product. License keys are non-transferable and must not be shared or sold to third parties."
            ],
            footer: "If you encounter issues during the activation process or require more activations than initially granted, please reach out to our support team for assistance."
        },
        {
            title: "Accessing Updates for Software and Digital Products ",
            description: "Pixel Bharat regularly updates its software products to enhance functionality, fix bugs, or introduce new features. Access to updates depends on the type of product you purchase: ",
            points: [
                "Automatic Updates: For certain software, automatic updates are included, and the software will prompt you to install updates when available. ",
                "Manual Updates: In some cases, you will need to manually download the latest version of the software from your Pixel Bharat account or through a provided download link. ",
                "Subscription-Based Products: If your purchase is tied to a subscription, you will automatically receive updates as part of your subscription for the duration of your membership. Failure to renew your subscription may result in losing access to future updates. "
            ]
        },
        {
            title: "Non-Refundable Nature of Digital Products",
            description: "Since Pixel Bharat provides digital goods, which cannot be physically returned, all sales are final and non-refundable once the product has been accessed or downloaded. We do not provide refunds for digital products after they have been successfully delivered unless there are exceptional circumstances, such as: ",
            points: [
                "Technical Errors: If there is a technical issue preventing you from accessing or downloading the product, and the issue cannot be resolved by our support team, we may consider a refund or replacement product. ",
                "Incorrect Purchases: If you accidentally purchase the wrong product, please contact our support team immediately. If the product has not been downloaded or accessed, we may offer a refund or exchange at our discretion. ",
            ],
            footer: "To request assistance with refunds, exchanges, or technical issues, please contact contact@pixelbharat.com with your order details. "
        },
        {
            title: "Security and Safe Download Practices",
            description: "At Pixel Bharat, we take security seriously. All of our digital products are scanned for malware, viruses, or malicious code prior to delivery to ensure that your downloads are safe. We recommend the following safe download practices to ensure your devices and data remain secure: ",
            points: [
                "Use Secure Devices: Always download digital products onto secure devices that are protected by updated antivirus software. ",
                "Monitor Links: Only download from the official links provided by Pixel Bharat. Do not trust unauthorized sources or third-party sites claiming to distribute our products. ",
                "Check for Updates: Regularly check for updates to the digital products and software you purchase from Pixel Bharat to ensure that you are using the most secure and up-to-date version of the product. "
            ]
        },
        {
            title: "Geographic Restrictions ",
            description: "While Pixel Bharat delivers digital products to customers worldwide, certain restrictions may apply depending on your location. Local laws and regulations regarding the distribution and use of digital products may vary by country. ",
            footer: "Pixel Bharat complies with all applicable laws and regulations concerning digital delivery. It is your responsibility to ensure that the products or services you are purchasing comply with your local laws and are legally accessible from your region. "
        },
        {
            title: "Intellectual Property Rights ",
            description: "All digital products, software, and content delivered by Pixel Bharat are protected by intellectual property laws, including copyright and trademark laws. Customers are granted a limited, non-transferable, non-exclusive license to use the purchased digital products for personal or internal business purposes only. You may not: ",
            points: [
                "Redistribute, resell, lease, or share our digital products with third parties. ",
                "Use our products for any unlawful or prohibited purposes",
                "Claim ownership or authorship of the digital products you purchase."
            ],
            footer: "Unauthorized use or distribution of Pixel Bharat's digital products may result in legal action. "
        },
        {
            title: "Customer Support ",
            description: "If you experience any issues with accessing or downloading your digital products, or if you have any questions about your purchase, our customer support team is here to help. We provide support for: ",
            points: [
                "Troubleshooting technical issues with downloads or installations. ",
                "Assisting with lost or expired download links. ",
                "Helping with product activation or licensing issues. ",
                "Answering questions about your account or purchase history. "
            ],
            footer: "To reach our support team, contact us at contact@pixelbharat.com, or visit our Help Center on the Pixel Bharat website for more resources. "
        },
        {
            title: "Modifications to This Policy ",
            description: "Pixel Bharat reserves the right to modify or update this Shipping Policy at any time without prior notice. Any changes will be effective immediately upon posting. We encourage you to review this policy regularly to stay informed about how digital products are delivered. ",
            footer: "By purchasing digital products or software from Pixel Bharat, you acknowledge that you have read, understood, and agreed to this Shipping Policy. If you have any questions, please do not hesitate to contact our support team. "
        },




    ];

    return (
        <div>
            <Header />
            <div className="w-full flex flex-col py-10 space-y-20">
                <div className="flex flex-col space-y-4 px-8">
                    <h1 className="text-[#141414] text-[36px] sm:text-[48px] lg:text-[74px] font-bold">Shipping Policy for <br /> Digital Products & Software</h1>
                    <p className="text-[#4A4A4A] text-[14px] sm:text-[16px] font-[Inter] not-italic">
                        At Pixel Bharat, we specialize in providing digital products and software solutions to our customers, including templates, graphics, plugins, apps, and other downloadable content. As a fully digital service provider, our products are delivered electronically, eliminating the need for physical shipping. This policy outlines the specifics of our digital delivery processes, how you can access your purchased digital products, and important considerations regarding downloads, product access, and applicable laws.
                        Please carefully review this Shipping Policy, as your purchase and use of our services signify your agreement to the terms outlined here.
                    </p>
                </div>
                <div className="flex flex-col min-h-[510px] p-6 sm:p-8 lg:p-[64px] mx-4 sm:mx-8 bg-[#F6F6F6] gap-8 rounded-[36px]">

                    {data.map((item, index) => (
                        <div key={index} className="flex flex-col gap-4">
                            <h2 className="text-[#000] text-[20px] sm:text-[24px] font-semibold">
                                {index + 1}. {item.title}
                            </h2>
                            <p className="text-[#4A4A4A] text-[14px] sm:text-[16px] font-[Inter] not-italic">
                                {item.description}
                            </p>
                            {item.subItems && (
                                <div className="pl-4 sm:pl-6 md:pl-8 pt-2 sm:pt-2 space-y-4">
                                    {item.subItems.map((subItem, subIndex) => (
                                        <div key={subIndex} className="flex flex-col gap-2">
                                            <h2 className="text-[18px] sm:text-[20px] font-semibold font-[Inter] not-italic">
                                                {index + 1}.{subIndex + 1} {subItem.title}
                                            </h2>
                                            <p className="text-[#4A4A4A] text-[14px] sm:text-[16px] font-[Inter] not-italic">
                                                {subItem.description}
                                            </p>
                                            {subItem.points && (
                                                <ul className="list-disc list-inside pl-4 text-[#4A4A4A] text-[14px] sm:text-[16px] font-[Inter] space-y-1">
                                                    {subItem.points.map((point, pointIndex) => (
                                                        <li key={pointIndex}>{point}</li>
                                                    ))}
                                                </ul>
                                            )}
                                            <p className="text-[#4A4A4A] text-[14px] sm:text-[16px] font-[Inter] not-italic">
                                                {subItem.description}
                                            </p>
                                        </div>
                                    ))}
                                </div>
                            )}
                            {item.points && (
                                <ul className="list-disc list-inside pl-4 text-[#4A4A4A] text-[14px] sm:text-[16px] font-[Inter] space-y-1">
                                    {item.points.map((point, pointIndex) => (
                                        <li key={pointIndex}>{point}</li>
                                    ))}
                                </ul>
                            )}
                        </div>
                    ))}
                </div>
            </div>

            <Footer />
        </div>
    )
}
