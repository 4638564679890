import React from 'react'
import Header from '../componets/Header';
import Footer from '../componets/Footer';

export default function PrivacyPolicy() {

    const data = [
        {
            title: "Privacy Notice",
            description: "Pixel Bharat and its Affiliates (collectively, “Pixel Bharat,” “we,” “us,” or “our”) are committed to safeguarding your privacy while delivering cutting-edge solutions powered by cloud, mobile, and digital technologies. Since our inception, we have strived to provide transparent services and ensure our users' data security. This Privacy Policy describes how Pixel Bharat collects, uses, stores, and shares your personal information and explains your rights in accordance with applicable privacy laws. ",

            footer: "By using our services, products, or websites, you agree to the collection and use of your information as described in this Privacy Policy. Pixel Bharat complies with international privacy regulations such as the General Data Protection Regulation (GDPR) and the California Consumer Privacy Act (CCPA). We prioritize transparency, lawfulness, and accountability in data collection and processing. "
        },
        {
            title: "Why We Collect Your Information",
            description: "We collect personal and non-personal data in several ways depending on the services or products you use. ",
            subItems: [
                {
                    title: "Information You Provide ",
                    description: "When you contact us, create an account, request a demo, or attend webinars, we collect personal details such as your name, email address, and contact information. You may also provide information by participating in surveys, community discussions, or applying for employment. ",
                },
                {
                    title: "Payment Information ",
                    description: "We collect personal details related to purchases made on our platform, including payment and billing information such as first and last names, email addresses, and contact details. However, we do not store credit card details on our platform. ",
                },
                {
                    title: "Information About Usage ",
                    description: "We gather certain data automatically when you interact with our website or products. This data may include your browser type, operating system, referral URLs, page visit details, IP address, and interaction times. ",
                },
                {
                    title: "Information From Third Parties ",
                    description: "We may collect data from third-party partners or service providers with whom you have agreed to share your data. This may include contact information, but we always seek explicit consent before further interactions. ",
                },
            ],
        },
        {
            title: "Why We Collect Your Information ",
            description: "Pixel Bharat collects and processes your information for the following reasons: ",
            points: [
                "To provide a personalized user experience and optimize website content. ",
                "To improve services and ensure site security and performance. ",
                "To fulfill legal obligations in cases of fraud, data breaches, or law enforcement requests. ",
                "To communicate with you about services and offers, subject to your consent. "
            ],
            footer: "Data is processed for these purposes only with your prior consent, which can be withdrawn at any time by emailing us at contact@pixelbharat.com. "
        },
        {
            title: "Cookies ",
            description: "Our website uses cookies to enhance your experience. We use persistent and session cookies to improve navigation, maintain sessions, and collect analytical data about user behavior. ",
            footer: "For more information on our cookie practices, please refer to our Cookie Policy. "
        },
        {
            title: "Sharing Personal Information ",
            description: "Pixel Bharat does not sell your personal information. However, we may share information internally or with trusted third-party service providers under specific conditions: ",
            subItems: [
                {
                    title: "Affiliates ",
                    description: "We share information across Pixel Bharat’s entities and affiliates when necessary to deliver our services or improve user experiences. ",
                },
                {
                    title: "Service Providers ",
                    description: "External service providers may access personal information as part of external audits, security compliance, or service optimization. These entities are bound by confidentiality agreements to protect your information. ",
                },
                {
                    title: "Legal Obligations ",
                    description: "External service providers may access personal information as part of external audits, security compliance, or service optimization. These entities are bound by confidentiality agreements to protect your information. ",
                },
            ],
        },
        {
            title: "Data Security ",
            description: "We take data security seriously. Pixel Bharat uses industry-standard security measures, including encryption and third-party vulnerability assessments, to safeguard your data. We comply with ISO 27001 and GDPR standards. ",
            footer: "In the event of a security breach, we will notify affected individuals and relevant authorities as required by law. "
        },
        {
            title: "Data Retention ",
            description: "Pixel Bharat retains your data for as long as necessary to fulfill contractual obligations, or as required by law. You may request the deletion of your data at any time by contacting us at contact@pixelbharat.com. ",
        },
        {
            title: "Children’s Privacy ",
            description: "Our services are not intended for users under 16 years of age. We do not knowingly collect personal data from children. If a parent or guardian becomes aware that a child has provided personal information to us, please contact us immediately. ",
        },
        {
            title: " Your Rights ",
            description: "As a user, you have specific rights regarding your personal data: ",

            subItems: [
                {
                    title: "Right of Confirmation ",
                    description: "You may confirm if your personal data is being processed by Pixel Bharat. ",
                },
                {
                    title: "Right of Access ",
                    description: "You have the right to access your personal data and obtain information on how it is processed and why. ",
                },
                {
                    title: "Right to Rectification ",
                    description: "You can request corrections to inaccurate or incomplete data held about you. ",
                },
                {
                    title: "Right to Erasure ",
                    description: "You may request the deletion of your personal data under certain circumstances, such as when the data is no longer necessary for the purpose it was collected. ",
                },
                {
                    title: "Right to Data Portability ",
                    description: "You may request that your personal data be transferred to another service provider in a structured, machine-readable format. ",
                },
                {
                    title: "Right to Object ",
                    description: "You may object to the processing of your data for specific purposes. ",
                },
                {
                    title: "Right to Withdraw Consent ",
                    description: "You can withdraw consent for processing your personal data at any time by contacting us.",
                },
            ],
        },
        {
            title: "California Privacy Rights ",
            description: "If you are a California resident, the CCPA grants you specific rights regarding your personal data. You can request information about what data we collect and request its deletion. For more information, please email contact@pixelbharat.com with the subject line “California Privacy Rights Request.” ",

        },
        {
            title: "Copyright Notice  ",
            description: "All content, including design, text, and graphics on our website, is the property of Pixel Bharat. Downloading content is permitted for personal use only. No content may be copied or reproduced without our prior written consent. ",
        },
        {
            title: "Changes to This Privacy Policy",
            description: "Pixel Bharat reserves the right to modify this Privacy Policy. Any updates will be posted on this page, and we encourage users to review it periodically for changes. The revised policy will take effect immediately upon posting. ",

        },
        {
            title: "How to Contact Us ",
            description: "If you have any questions or concerns about this Privacy Policy or how we handle your data, you can contact our Data Protection Officer (DPO) at the following address: ",
            points: [
                "Email: contact@pixelbharat.com ",
                "Phone: +91-80-1234-5678 ",
            ],
            footer: "For any data privacy inquiries or rights requests, please contact us at contact@pixelbharat.com or info@pixelbharat.com. "
        },

    ];

    return (
        <div>
            <Header />
            <div className="w-full flex flex-col py-10 space-y-20">
                <div className="flex flex-col space-y-4 px-8">
                    <h1 className="text-[#141414] text-[36px] sm:text-[48px] lg:text-[74px] font-bold">Privacy Policy</h1>
                    <p className="text-[#4A4A4A] text-[14px] sm:text-[16px] font-[Inter] not-italic">
                        Last Modified: October 3, 2024
                    </p>
                </div>
                <div className="flex flex-col min-h-[510px] p-6 sm:p-8 lg:p-[64px] mx-4 sm:mx-8 bg-[#F6F6F6] gap-8 rounded-[36px]">

                    {data.map((item, index) => (
                        <div key={index} className="flex flex-col gap-4">
                            <h2 className="text-[#000] text-[20px] sm:text-[24px] font-semibold">
                                {index + 1}. {item.title}
                            </h2>
                            <p className="text-[#4A4A4A] text-[14px] sm:text-[16px] font-[Inter] not-italic">
                                {item.description}
                            </p>
                            {item.subItems && (
                                <div className="pl-4 sm:pl-6 md:pl-8 pt-2 sm:pt-2 space-y-4">
                                    {item.subItems.map((subItem, subIndex) => (
                                        <div key={subIndex} className="flex flex-col gap-2">
                                            <h2 className="text-[18px] sm:text-[20px] font-semibold font-[Inter] not-italic">
                                                {index + 1}.{subIndex + 1} {subItem.title}
                                            </h2>
                                            <p className="text-[#4A4A4A] text-[14px] sm:text-[16px] font-[Inter] not-italic">
                                                {subItem.description}
                                            </p>
                                            {subItem.points && (
                                                <ul className="list-disc list-inside pl-4 text-[#4A4A4A] text-[14px] sm:text-[16px] font-[Inter] space-y-1">
                                                    {subItem.points.map((point, pointIndex) => (
                                                        <li key={pointIndex}>{point}</li>
                                                    ))}
                                                </ul>
                                            )}


                                        </div>

                                    ))}
                                </div>
                            )}

                            {item.points && (
                                <ul className="list-disc list-inside pl-4 text-[#4A4A4A] text-[14px] sm:text-[16px] font-[Inter] space-y-1">
                                    {item.points.map((point, pointIndex) => (
                                        <li key={pointIndex}>{point}</li>
                                    ))}
                                </ul>
                            )}
                            <p className="text-[#4A4A4A] text-[14px] sm:text-[16px] font-[Inter] not-italic">
                                {item.footer}
                            </p>
                        </div>
                    ))}
                </div>
            </div>

            <Footer />
        </div>
    )
}
