import React, { useState, useEffect } from 'react'
import Header from '../componets/Header';
import Footer from '../componets/Footer';
import { useParams } from 'react-router-dom';
import { getBlogById, getSortedBlogs } from '../data/blogPosts';
import config from '../config';  // Add config import


export default function Blog() {

    const { id } = useParams();

    const [blog, setBlog] = useState(null);
    const [loading, setLoading] = useState(true);
    const [recentBlogs, setRecentBlogs] = useState([]);



    useEffect(() => {
        const fetchBlog = async () => {
            try {
                const response = await fetch(`${config.apiUrl}api/blogPosts/${id}`);
                const data = await response.json();
                setBlog(data);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching blog:', error);
                setLoading(false);
            }
        };
        fetchBlog();
    }, [id]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                // Fetch current blog
                const blogResponse = await fetch(`${config.apiUrl}api/blogPosts/${id}`);
                const blogData = await blogResponse.json();
                setBlog(blogData);

                // Fetch all blogs for recent posts
                const allBlogsResponse = await fetch(`${config.apiUrl}api/blogPosts/`);
                const allBlogsData = await allBlogsResponse.json();

                // Sort blogs by date and get latest 3
                const sortedBlogs = allBlogsData
                    .filter(blog => blog._id !== id)
                    .sort((a, b) => new Date(b.date) - new Date(a.date))
                    .slice(0, 3);

                setRecentBlogs(sortedBlogs);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching data:', error);
                setLoading(false);
            }
        };

        fetchData();
    }, [id]);

    if (loading) return <div>Loading...</div>;
    if (!blog) return <div>Blog not found</div>;

    return (
        <div>
            <Header />
            <div className="w-full flex flex-col py-10 space-y-10 lg:space-y-20">
                <div className="flex flex-col space-y-4 px-8">
                    <h1 className="text-[#141414] text-[36px] sm:text-[48px] lg:text-[74px] font-bold">{blog.title}</h1>
                    <p className="text-[#4A4A4A] text-[14px] font-bold sm:text-[16px] font-[Inter] not-italic">
                        {new Date(blog.date).toLocaleDateString()}
                    </p>
                    <p className="text-[#4A4A4A] text-[14px] sm:text-[16px] font-[Inter] not-italic">
                        {blog.description}
                    </p>
                </div>

                <div className="w-full h-1/2 px-8 rounded-[30px]">
                    <img src={`${config.apiUrl}uploads/${blog.image.split('/').pop()}`} alt={blog.title} className="w-full aspect-[3/1] rounded-[30px] object-cover" />
                </div>

                <div className="flex flex-col px-8 py-10 lg:flex-row space-y-4 lg:space-y-0 lg:space-x-[64px]">
                    <div className='space-y-8 lg:w-3/4 overflow-x-scroll w-full items-start gap-[32px] self-stretch '>
                        <div dangerouslySetInnerHTML={{ __html: blog.content }} />


                    </div>

                    <div className="flex flex-col gap-6 lg:w-1/4 w-full">
                        <h3 className="text-xl font-bold mb-6">Recent Posts</h3>
                        {recentBlogs.map(recentBlog => (
                            <div className="flex flex-col gap-4 pt-4" key={recentBlog.id}>
                                <div className="rounded-[24px] relative overflow-hidden">
                                    <img src={`${config.apiUrl}uploads/${recentBlog.image.split('/').pop()}`} alt={recentBlog.alt} className="w-full aspect-[3/2]  object-cover rounded-[24px]" />
                                    <h1 className="absolute top-0 right-0 bg-white py-2 px-4 m-4 text-sm rounded-[100px]">{recentBlog.category}</h1>
                                </div>
                                <div className="flex flex-col gap-4">
                                    <h1 className="font-bold text-xl mb-2">{recentBlog.title}</h1>
                                    <div className="font-[Inter] flex gap-4 items-center text-[#9A9A9A] text-[14px] font-medium  pt-[16px] self-stretch border-t-[0.5px] border-solid border-[#F3F3F3]">
                                        <p className="">{new Date(recentBlog.date).toLocaleDateString()}</p>
                                        <div className='bg-black h-2 w-2 rounded-xl'></div>
                                        <p className="">Blog</p>
                                    </div>
                                </div>
                            </div>
                        ))}
                        <p className=" italic mt-4 text-[#ABABAB] text-[16px] w-fit  font-[Inter] border-b-[1px] border-[#ABABAB] border-solid">See More</p>
                    </div>


                </div>
            </div>

            <Footer />
        </div>
    )
}
