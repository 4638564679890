import React from 'react'
import Header from '../componets/Header';
import Footer from '../componets/Footer';

export default function TermsAndConditions() {

    const data = [
        {
            title: "Acceptance of Terms ",
            description: "Your access to and use of the Pixel Bharat website and its associated services, including software products and digital assets, is subject to the following terms. These terms may be updated periodically without prior notice, so we encourage you to check them regularly. If you do not agree with any of the terms outlined here, you must cease using our services immediately. ",

        },
        {
            title: "Description of Website Services ",
            description: "Pixel Bharat offers a wide range of services, including software products, digital assets, web-based tools, and consulting services designed to help users improve their digital engagement and professional activities. We may add new features, services, or products at any time, which will be subject to these Terms & Conditions unless otherwise stated. We reserve the right to modify, suspend, or discontinue any aspect of our website or services at any time without prior notice. It is your responsibility to obtain access to the Internet and any required hardware or software to utilize our services. ",

        },
        {
            title: "Intellectual Property Ownership & License ",
            description: "The content of the Pixel Bharat website, including but not limited to software, digital assets, images, text, graphics, logos, trademarks, videos, and any other proprietary material, is protected by Indian and international intellectual property laws. Pixel Bharat retains full ownership of all website content and software products unless explicitly stated otherwise. Users are granted a limited, non-exclusive, non-transferable, non-commercial license to access and use Pixel Bharat's software products and digital assets for personal or internal business use. The license provided to you does not grant ownership of the software, but merely the right to use it in compliance with these Terms & Conditions. Without the express written permission of Pixel Bharat, you are prohibited from: ",

            points: [
                "Modifying, reproducing, distributing, transmitting, or displaying any of the content provided by Pixel Bharat. ",
                "Creating derivative works from our software products or digital assets. ",
                "Engaging in any form of commercial exploitation of our intellectual property, including selling, reselling, or leasing our software products. ",
                "Reverse-engineering, decompiling, or otherwise attempting to discover the source code of any software or digital assets provided by Pixel Bharat. ",
            ],
            footer: "Failure to comply with this section may result in immediate termination of access to our services and legal action. "
        },
        {
            title: "Software Products and Digital Assets ",
            description: "Pixel Bharat offers various software products, digital assets, and tools that may be downloaded or accessed through our website or provided as part of a service. These products are licensed, not sold, to you, and they are subject to the terms set forth here. All software provided by Pixel Bharat, whether downloadable or web-based, is protected by copyright and other intellectual property laws. ",
            subItems: [
                {
                    title: "License to Use Software Products ",
                    description: "Subject to your compliance with these Terms & Conditions, Pixel Bharat grants you a non-exclusive, non-transferable, revocable, limited license to install, access, and use the software products and digital assets we provide solely for your personal use or internal business operations. Any other use, including but not limited to, redistribution, modification, or public display of our software products, is strictly prohibited unless authorized in writing by Pixel Bharat. ",
                },
                {
                    title: "Prohibited Uses ",
                    description: "By using our software products and digital assets, you agree that you will not: ",
                    points: [
                        "Modify or create derivative works based on the software. ",
                        "Disassemble or reverse-engineer the software to attempt to derive the source code. ",
                        "Rent, lease, lend, or distribute the software or make it available to any third party without explicit authorization. ",
                        "Use the software in violation of any applicable laws or regulations. ",
                        "Transfer the software license to another party without the prior written consent of Pixel Bharat. ",
                    ],
                },

            ],
        },
        {
            title: "Updates and Support for Software Products ",
            description: "Pixel Bharat may, at its discretion, provide updates or patches for its software products to improve performance, enhance security, or fix bugs. These updates may be mandatory, and failure to install them may result in loss of functionality or access to certain features. Pixel Bharat is under no obligation to provide ongoing support, maintenance, or future updates to any software products unless otherwise specified in a separate agreement. ",
            footer: "For technical support, please refer to our customer service channels. We strive to provide the best support possible, but we do not guarantee that every issue will be resolved to your satisfaction. "
        },
        {
            title: "User Responsibilities and Acceptable Use Policy ",
            description: "You agree to use Pixel Bharat services, software products, and digital assets responsibly and in compliance with all applicable laws. Specifically, you must not:",
            points: [
                "Upload, distribute, or make available any content that is illegal, abusive, defamatory, harmful, or that promotes violence, hatred, or illegal activities. ",
                "Infringe upon the intellectual property rights of Pixel Bharat or any third party. ",
                "Disrupt the services provided by Pixel Bharat or attempt to gain unauthorized access to our systems. ",
                "Use our software products or digital assets to engage in any form of unsolicited marketing or commercial activities (e.g., spam, pyramid schemes, etc.). ",
                "Share your account credentials with others or allow unauthorized individuals to access your account. ",
            ],
            footer: "Violation of this acceptable use policy may result in suspension or termination of your account, as well as legal action if necessary. "
        },

        {
            title: "User Accounts and Privacy ",
            description: "To access certain services, software, or digital assets provided by Pixel Bharat, you may be required to create a user account. You agree to provide accurate, current, and complete information during the registration process and to update this information as necessary to keep it current. You are responsible for maintaining the confidentiality of your account credentials and for all activities that occur under your account. ",
            footer: "Pixel Bharat is committed to protecting your privacy. Our Privacy Policy outlines how we collect, store, and use your personal data. By using our website and services, you consent to the collection and use of your data in accordance with our Privacy Policy. We comply with international data protection regulations, including but not limited to the General Data Protection Regulation (GDPR) for users within the European Economic Area (EEA) and similar regulations applicable in other jurisdictions. "
        },
        {
            title: "International Data Protection and Compliance",
            description: "For users outside India, Pixel Bharat takes into account the data protection laws of their respective countries, including regulations such as the GDPR in Europe and the CCPA in the United States. Your personal data will be handled in accordance with these international regulations, and you have the right to request access to, ",
            footer: "modification of, or deletion of your personal data at any time by contacting our support team. "
        },
        {
            title: "Payments and Subscription Services ",
            description: "All content, including design, text, and graphics on our website, is the property of Pixel Bharat. Downloading content is permitted for personal use only. No content may be copied or reproduced without our prior written consent. ",
            subItems: [
                {
                    title: "Billing and Renewals ",
                    description: "If you sign up for a subscription service, you authorize Pixel Bharat to charge your payment method on a recurring basis for the duration of the subscription. Subscription renewals will be automatically billed unless you cancel your subscription before the renewal date. ",
                },
                {
                    title: "Cancellation ",
                    description: "You may cancel your subscription at any time, but no refunds will be provided for any remaining subscription period unless explicitly stated otherwise. Your access to the service will continue until the end of your current billing cycle. ",
                },
            ],
        },
        {
            title: "Links to Third-Party Websites",
            description: "The Pixel Bharat website may contain links to third-party websites. We are not responsible for the content, privacy practices, or terms of service of those third-party websites. The inclusion of any link does not imply endorsement by Pixel Bharat of the Other Sites. Your interactions with such websites are at your own risk, and we advise you to review their terms and policies before proceeding. ",

        },
        {
            title: "Promotions and Offers ",
            description: "From time to time, Pixel Bharat may offer promotions, discounts, contests, or sweepstakes. These activities may be governed by separate terms, which will be provided at the time of the promotion. It is your responsibility to review and understand those terms before participating. ",

        },
        {
            title: "Indemnification ",
            description: "You agree to indemnify and hold Pixel Bharat, its affiliates, officers, agents, and employees harmless from any claims, damages, liabilities, and expenses, including legal fees, arising out of your use of the website, software products, digital assets, or your violation of these Terms & Conditions. ",

        },
        {
            title: "Limitation of Liability",
            description: "To the fullest extent permitted by applicable law, Pixel Bharat will not be liable for any indirect, incidental, special, consequential, or punitive damages, or any loss of profits or revenues, arising from your use of our services, software products, or digital assets. ",
        },
        {
            title: "Governing Law and Jurisdiction",
            description: "These Terms & Conditions shall be governed by and construed in accordance with the laws of India. Any disputes arising out of or in connection with these terms will be subject to the exclusive jurisdiction of the courts in India. For international users, local consumer protection laws may apply. ",
            footer: "By using Pixel Bharat’s services, software products, or digital assets, you acknowledge that you have read, understood, and agree to be bound by these Terms & Conditions. If you do not agree, you must discontinue your use of the website and its associated services immediately. "
        },

    ];

    return (
        <div>
            <Header />
            <div className="w-full flex flex-col py-10 space-y-20">
                <div className="flex flex-col space-y-4 px-8">
                    <h1 className="text-[#141414] text-[36px] sm:text-[48px] lg:text-[74px] font-bold">Terms &
                        Conditions</h1>
                    <p className="text-[#4A4A4A] text-[14px] sm:text-[16px] font-[Inter] not-italic">
                        Welcome to the official Terms & Conditions of Pixel Bharat and its affiliates (“Pixel Bharat,” “we,” or “us”). By accessing or using any of our services, websites, or products, including any software products or digital assets provided by us, you agree to these Terms & Conditions. Please read these terms carefully. Your continued use of our services constitutes your acceptance of these terms. Failure to comply with these conditions may result in restricted access to our services or legal action.
                    </p>
                </div>
                <div className="flex flex-col min-h-[510px] p-6 sm:p-8 lg:p-[64px] mx-4 sm:mx-8 bg-[#F6F6F6] gap-8 rounded-[36px]">

                    {data.map((item, index) => (
                        <div key={index} className="flex flex-col gap-4">
                            <h2 className="text-[#000] text-[20px] sm:text-[24px] font-semibold">
                                {index + 1}. {item.title}
                            </h2>
                            <p className="text-[#4A4A4A] text-[14px] sm:text-[16px] font-[Inter] not-italic">
                                {item.description}
                            </p>
                            {item.subItems && (
                                <div className="pl-4 sm:pl-6 md:pl-8 pt-2 sm:pt-2 space-y-4">
                                    {item.subItems.map((subItem, subIndex) => (
                                        <div key={subIndex} className="flex flex-col gap-2">
                                            <h2 className="text-[18px] sm:text-[20px] font-semibold font-[Inter] not-italic">
                                                {index + 1}.{subIndex + 1} {subItem.title}
                                            </h2>
                                            <p className="text-[#4A4A4A] text-[14px] sm:text-[16px] font-[Inter] not-italic">
                                                {subItem.description}
                                            </p>
                                            {subItem.points && (
                                                <ul className="list-disc list-inside pl-4 text-[#4A4A4A] text-[14px] sm:text-[16px] font-[Inter] space-y-1">
                                                    {subItem.points.map((point, pointIndex) => (
                                                        <li key={pointIndex}>{point}</li>
                                                    ))}
                                                </ul>
                                            )}
                                        </div>
                                    ))}
                                </div>
                            )}
                            {item.points && (
                                <ul className="list-disc list-inside pl-4 text-[#4A4A4A] text-[14px] sm:text-[16px] font-[Inter] space-y-1">
                                    {item.points.map((point, pointIndex) => (
                                        <li key={pointIndex}>{point}</li>
                                    ))}
                                </ul>
                            )}
                        </div>
                    ))}
                </div>
            </div>

            <Footer />
        </div>
    )
}
